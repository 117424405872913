import { ClientSubProjectData } from '@modules/EvaluationModule/interfaces/ClientSubProjectData';
import { ClientSubProjectFilterOptions } from '@modules/EvaluationModule/interfaces/ClientSubProjectFilterOptions';
import { ClietContactFormData } from '@modules/EvaluationModule/interfaces/ClietContactFormData';
import { FeedbackGiver, WorkstreamStatus } from '../enums/ContactFilterOptions';
import { ClientRmContactStatus } from '@modules/EvaluationModule/enums/ClientRmContactStatus';

export const filterClientContacts = (
  workstreams: ClientSubProjectData[],
  filters: ClientSubProjectFilterOptions
): {
  filteredClientSubProjectData: ClientSubProjectData[];
  numberOfFilters: number;
  noContactsAvailable: boolean;
} => {
  const filteredClientSubProjectData = workstreams
    .filter((workstream) => {
      return workstream.status === WorkstreamStatus.Closed ? filters.includeClosedWorkstreams : true;
    })
    .map((workstream) => {
      const filteredContacts = workstream.contacts.filter((contact) => {
        return (
          (filters.typeOfClient.length === 0 || filters.typeOfClient.includes(contact.rmTypeOfContact)) &&
          (filters.clientTitle.length === 0 || filters.clientTitle.includes(contact.title)) &&
          (filters.status.length === 0 ||
            (contact.rmContactStatus && filters.status.includes(contact.rmContactStatus))) &&
          (filters.contactOwner.length === 0 ||
            (contact.contactOwner && filters.contactOwner.includes(contact.contactOwner))) &&
          (filters.contactLocation.length === 0 || filters.contactLocation.includes(contact.location)) &&
          (filters.locationArea.length === 0 || filters.locationArea.includes(contact.locationArea)) &&
          satisfiesFeedbackFilter(contact, filters.feedbackGiver)
        );
      });
      return { ...workstream, contacts: filteredContacts };
    });

  const numberOfFilters = Object.keys(filters).reduce((totalFilters, key) => {
    const filterValue = filters[key as keyof ClientSubProjectFilterOptions];

    if (filterValue === true || (Array.isArray(filterValue) && filterValue.length)) {
      return totalFilters + 1;
    }

    return totalFilters;
  }, 0);

  const noContactsAvailable = Boolean(
    !filteredClientSubProjectData.filter((workstream) => workstream.contacts.length > 0).length
  );

  const sortedAndFilteredClientSubProjectData = filteredClientSubProjectData.map((subProject) => {
    const sortedContacts = subProject.contacts.sort((a, b) => {
      const priorityStatuses = [ClientRmContactStatus.currentPoc, ClientRmContactStatus.mainCurrentPoc];

      const aPriority = priorityStatuses.includes(a.rmContactStatus as ClientRmContactStatus) ? 0 : 1;
      const bPriority = priorityStatuses.includes(b.rmContactStatus as ClientRmContactStatus) ? 0 : 1;

      return aPriority - bPriority;
    });

    return { ...subProject, contacts: sortedContacts };
  });

  const accountLevelContacts = sortedAndFilteredClientSubProjectData.filter((subProject) => !subProject.isWorkstream);
  const workstreamContacts = sortedAndFilteredClientSubProjectData
    .filter((subProject) => subProject.isWorkstream)
    .sort((a, b) => {
      const aPriority = a.contacts.length > 0 ? 0 : 1;
      const bPriority = b.contacts.length > 0 ? 0 : 1;
      return aPriority - bPriority;
    });

  const sortedAndFilteredClientSubProjectDataWithPriority = [
    ...(workstreamContacts.length > 0 ? workstreamContacts : []),
    ...(accountLevelContacts.length > 0
      ? [
          {
            name: 'Not associated with workstreams (subteams)',
            contacts: accountLevelContacts.flatMap((workstream) => workstream.contacts),
            isWorkstream: false,
            id: accountLevelContacts[0]?.id,
            status: accountLevelContacts[0]?.status,
          },
        ]
      : []),
  ];

  return {
    filteredClientSubProjectData: sortedAndFilteredClientSubProjectDataWithPriority,
    numberOfFilters,
    noContactsAvailable,
  };
};

function satisfiesFeedbackFilter(contact: ClietContactFormData, feedbackFilter: string[]): boolean {
  return (
    feedbackFilter.length === 0 ||
    feedbackFilter.every((filter) => {
      switch (filter) {
        case FeedbackGiver.CsatGiver:
          return contact.csatGiver;
        case FeedbackGiver.FeedbackGiver:
          return contact.feedbackGiver;
        case FeedbackGiver.NotCsatGiver:
          return !contact.csatGiver;
        case FeedbackGiver.NotFeedbackGiver:
          return !contact.feedbackGiver;
        default:
          return true;
      }
    })
  );
}
