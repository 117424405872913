import { AxiosInstance, AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/react';
import { firebaseAuth } from '../../auth/firebase';
import { Enviroment } from '../enums/EnvironmentNames';
import { getAccessToken, getCustomAuth, isCustomTokenExpired, refreshCustomAuth } from './auth';

const buildConfig = (config: AxiosRequestConfig, token: string) => {
  return {
    ...config,
    headers: {
      ...config.headers,
      ...(token && { Authorization: `Bearer ${token}` }),
    },
  };
};

export const httpInterceptorsRequestUse = (axiosInstance: AxiosInstance): number =>
  axiosInstance.interceptors.request.use(async (config: AxiosRequestConfig) => {
    const customAuth = getCustomAuth();
    const expireDate = new Date(customAuth.expiresIn || '');

    const currentUser = firebaseAuth?.currentUser;

    if (!currentUser && !customAuth.email) {
      const userToken = getAccessToken();
      if (userToken) {
        return buildConfig(config, userToken);
      }
      return config;
    }

    if (
      (process.env.REACT_APP_ENV === Enviroment.Staging ||
        process.env.REACT_APP_ENV === Enviroment.Develop ||
        process.env.REACT_APP_ENV === Enviroment.Local) &&
      customAuth.token
    ) {
      if (customAuth.email && isCustomTokenExpired(expireDate)) {
        const refreshedCustomAuth = await refreshCustomAuth();
        return buildConfig(config, refreshedCustomAuth.idToken);
      }
      return buildConfig(config, customAuth.token);
    } else if (currentUser) {
      const firebaseToken = await currentUser?.getIdToken();
      return buildConfig(config, firebaseToken);
    }
  });

export const httpInterceptorsResponseUse = (axiosInstance: AxiosInstance): number =>
  axiosInstance.interceptors.response.use(
    (resp) => resp,
    (error) => {
      const { response } = error;
      const status = response ? response.status : error.status;
      if (status === 500) {
        const message = response && response.data ? response.data.Message : error.message;
        Sentry.captureMessage(`${status}: ${message}`, Sentry.Severity.Error);
      }
      return Promise.reject(error);
    }
  );
